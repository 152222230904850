export default {
    data() {
        return {
            categoryData: {
                "9": "02afff",
                "10": "7ccbf8",
                "20": "a6dfff",
                "30": "94ecfe",
                "40": "33a3bb",
                "50": "126b7e",
                "60": "417ffc",
                "70": "7ccbf8",
                "80": "9d8dff",
                "90": "5f45ff",
                "100": "153679",
                "110": "1346ad",
                "009": "0191e4"
            },
            categoryList: [],
            mycharts1: {}
        }
    },
    methods: {
        getPieMap() {
            this.mycharts1 = this.$echarts.init(document.getElementById("pieMap"));
            let option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '0',
                    top: "5%",
                    textStyle: {
                        color: '#9fceff'
                    },
                },
                series: [{
                    name: '实时行业数据',
                    type: 'pie',
                    radius: '70%',
                    center: ['70%', '60%'],
                    data: this.categoryList.map(item => {
                        return {
                            name: item.categoryName,
                            value: item.taskNum,
                            itemStyle: {
                                color: '#'+this.categoryData[item.id]
                            }
                        }
                    }),
                    label: {
                        show: false,
                    },
                    emphasis: {
                        show: false
                    }
                }]
            };
            this.mycharts1.setOption(option);
        }
    },
    mounted() {
        this.$api.get('/api/v1/getAllCategoryStatistics.json').then(res => {
            this.categoryList = res;
            this.getPieMap();
        })
    }
}