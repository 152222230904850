<template>
  <div class="mack-offer">
    <div class="content">
      <div :class="['file-list', hide ? 'on' : '']">
        <el-form
          :model="forms"
          ref="tablelists"
          label-width="100px"
          size="small"
          class="tablelists-form"
        >
          <el-table
            size="small"
            border
            :data="forms.yzAutoPriceItemList"
            style="width: 100%"
          >
            <el-table-column
              width="200"
              prop="fileName"
              align="center"
              label="零件名称"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.fileName`"
                  :rules="{
                    required: true,
                    message: '请输入零件名称',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    placeholder="请输入零件名称"
                    v-model="scope.row.fileName"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              prop="technology"
              align="center"
              label="工艺"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.technology`"
                >
                  <el-select
                    v-model="scope.row.technology"
                    placeholder="请选择"
                    @change="technologyChange"
                    value-key="dictValue"
                  >
                    <el-option
                      v-for="item in scope.row.gyList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="{
                        ...item,
                        yzAutoPriceId: scope.row.yzAutoPriceId,
                      }"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              prop="materialTexture"
              align="center"
              label="材质"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.materialTexture`"
                >
                  <el-select
                    v-model="scope.row.materialTexture"
                    value-key="dictValue"
                    :disabled="!scope.row.czList.length"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in scope.row.czList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="{
                        ...item,
                        yzAutoPriceId: scope.row.yzAutoPriceId,
                      }"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="surfaceTreatment"
              align="center"
              label="表面处理"
              width="150"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.surfaceTreatment`"
                >
                  <el-select
                    v-model="scope.row.surfaceTreatment"
                    :disabled="!scope.row.bmList.length"
                    placeholder="请选择"
                    value-key="dictValue"
                  >
                    <el-option
                      v-for="item in scope.row.bmList"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="{
                        ...item,
                        yzAutoPriceId: scope.row.yzAutoPriceId,
                      }"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              width="80"
              prop="color"
              align="center"
              label="颜色"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.color`"
                >
                  <el-color-picker
                    style="position: relative; top: 4px"
                    v-model="scope.row.color"
                  ></el-color-picker>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              prop="quantity"
              align="center"
              label="数量"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.quantity`"
                  :rules="{
                    required: true,
                    message: '请输入数量',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    type="number"
                    placeholder="请输入数量"
                    v-model="scope.row.quantity"
                  >
                    <span slot="suffix" class="unit">件</span>
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              prop="desciption"
              align="center"
              label="备注"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="`yzAutoPriceItemList.${scope.$index}.desciption`"
                >
                  <el-input
                    placeholder="请输入备注"
                    v-model="scope.row.desciption"
                  >
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column width="168" align="center" label="操作">
              <template slot-scope="scope">
                <el-form-item>
                  <el-button size="small" type="danger" @click="del(scope.row)">
                    删除
                  </el-button>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div class="btn">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus-outline"
            @click="add()"
            >添加新零件</el-button
          >
          <span class="tips">请准确填写需求信息，我们会尽快将报价通过短信和站内信通知您</span>
        </div>
        <div class="form-main">
          <el-form
            :model="forms"
            ref="forms"
            label-width="110px"
            class="forms-dynamic"
            label-suffix="："
            size="small"
          >
            <el-row :gutter="10">
              <el-col :span="isWap?22:7" v-if="!$store.state.userInfo.userCode">
                <el-form-item
                  prop="phone"
                  label="手机号码"
                  :rules="[
                    {
                      required: !$store.state.userInfo.userCode,
                      validator: validatorPhone,
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    type="number"
                    v-model="forms.phone"
                    placeholder="请输入手机号码"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item
                  prop="validCode"
                  label="图片验证码"
                  :rules="[
                    {
                      required: true,
                      message: '请输入图片验证码',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-row>
                    <el-col :span="14">
                      <el-input
                        v-model="forms.validCode"
                        placeholder="请输入图片验证码"
                      ></el-input>
                    </el-col>
                    <el-col :span="9" :offset="1" style="text-align: right">
                      <img :src="validCode" @click="getValidCode" alt="" />
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col> -->
              <el-col :span="isWap?22:7" v-if="!$store.state.userInfo.userCode">
                <el-form-item
                  prop="desciption"
                  label="短信验证码"
                  :rules="[
                    {
                      required: !$store.state.userInfo.userCode,
                      message: '请输入验证码',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-row>
                    <el-col :span="14">
                      <el-input
                        v-model="forms.desciption"
                        placeholder="请输入验证码"
                      ></el-input>
                    </el-col>
                    <el-col :span="9" :offset="1" style="text-align: right">
                      <el-button
                        type="primary"
                        :loading="codeLoad"
                        :disabled="
                          codeText != '发送验证码' &&
                          codeText != '重新发送验证码'
                        "
                        @click="getCode"
                        >{{ codeText }}</el-button
                      >
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="!$store.state.userInfo.userCode ? isWap?24:3 : 24">
                <div class="go-btn">
                  <el-button
                    type="primary"
                    @click="submitForm"
                    :loading="btnLoad"
                    :disabled="forms.yzAutoPriceItemList == 0"
                    size="small"
                    >获取报价</el-button
                  >
                </div>
                <p class="hint">
                  如有工艺不明确请
                  <a
                    href="https://www.huixianggongye.com/f/contactKefu"
                    target="_blank"
                    >在线咨询制造专家</a
                  >
                </p>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="down" @click="hide = !hide">
        {{ hide ? "展开报价工具" : "收起报价工具" }}
      </div>
    </div>
  </div>
</template>
<script>
import upload from "@/components/servers/upload";
import Snowflake from "@/common/snowflake";
export default {
  components: { upload },
  data() {
    return {
      hasLogin: false,
      btnLoading: false,
      validCode: "",
      codeText: "发送验证码",
      codeLoad: false,
      forms: {
        desciption: "",
        phone: "",
        yzAutoPriceItemList: [],
      },
      gyList: [],
      btnLoad: false,
      hide: false,
      isWap:false
    };
  },
  mounted() {
    this.isWap = $(window).width()<1000;
    this.gyList = [];
    this.$sapi
      .get("/api/v1/getDictDataList-yz_auto_price_technology.json")
      .then((res) => {
        let arr = [];
        let ids = [];
        let ids2 = [];
        let ids3 = [];
        let ids4 = [];
        res.forEach((item) => {
          if (item.treeLevel == 0) {
            arr.push(item);
          }
          if (item.treeLevel == 1) {
            if (ids[item.parentCode]) {
              ids[item.parentCode].push(item);
            } else {
              ids[item.parentCode] = [item];
            }
          }
          if (item.treeLevel == 2) {
            if (ids2[item.parentCode]) {
              ids2[item.parentCode].push(item);
            } else {
              ids2[item.parentCode] = [item];
            }
          }
          if (item.treeLevel == 3) {
            if (ids3[item.parentCode]) {
              ids3[item.parentCode].push(item);
            } else {
              ids3[item.parentCode] = [item];
            }
          }
          if (item.treeLevel == 4) {
            if (ids4[item.parentCode]) {
              ids4[item.parentCode].push(item);
            } else {
              ids4[item.parentCode] = [item];
            }
          }
        });
        arr = arr.map((item) => {
          if (ids[item.id]) {
            item.list = ids[item.id];
            item.list = item.list.map((items) => {
              if (ids2[items.id]) {
                items.list = ids2[items.id];
                items.list = items.list.map((items2) => {
                  if (ids3[items2.id]) {
                    items2.list = ids3[items2.id];
                    items2.list = items2.list.map((items3) => {
                      if (ids4[items3.id]) {
                        items3.list = ids4[items3.id];
                      }
                      return items3;
                    });
                  }
                  return items2;
                });
              }
              return items;
            });
          }
          return item;
        });
        this.gyList = arr[0].list;
        //console.log("工艺", this.gyList);
      });
    this.getValidCode();
  },
  methods: {
    over() {
      this.hide = true;
      this.btnLoad = false;
      this.forms = {
        desciption: "",
        phone: "",
        yzAutoPriceItemList: [],
      };
    },
    technologyChange(params) {
      this.forms.yzAutoPriceItemList = this.forms.yzAutoPriceItemList.map(
        (item) => {
          if (item.yzAutoPriceId == params.yzAutoPriceId) {
            item.czList = [];
            item.bmList = [];
            item.surfaceTreatment = "";
            item.materialTexture = "";
            item.gyList.forEach((items) => {
              if (items.dictValue == params.dictValue) {
                item.czList = items.list[0].list;
                item.bmList = items.list[1].list;
              }
            });
          }
          return item;
        }
      );
    },
    appendList(list) {
      list.forEach((item) => {
        this.add(item);
      });
    },
    validatorPhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value != "" && !/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    getValidCode() {
      this.validCode = "";
      this.$nextTick(() => {
        this.validCode = `${
          window.config.api
        }/validCode?${new Date().getTime()}`;
      });
    },
    getCode() {
      let i = 0;
      this.$refs.forms.validateField(["phone"], (err) => {
        if (!err) {
          i++;
          if (i == 1) {
            this.codeLoad = true;
            this.$api
              .post(`/api/v1/getPhoneCodeByMoible?mobile=${this.forms.phone}`)
              .then((res) => {
                this.codeLoad = false;
                if (res.code == 1) {
                  this.codeText = "60s后重新获取";
                  this.noteDisabled = true;
                  let _i = 59;
                  let _set = setInterval(() => {
                    if (_i > 0) {
                      this.codeText = `${_i}s后重新获取`;
                      _i--;
                    } else {
                      this.codeText = "发送验证码";
                      this.noteDisabled = false;
                      clearInterval(_set);
                    }
                  }, 1000);
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.codeLoad = false;
                this.$message({
                  message: err,
                  type: "error",
                });
              });
          }
        }
      });
    },
    submitForm() {
      this.$refs.tablelists.validate((valid) => {
        if (valid) {
          this.$refs.forms.validate((valid) => {
            if (valid) {
              this.btnLoad = true;
              let form = { ...this.forms };
              if (this.$store.state.userInfo.userCode) {
                form.phone = this.$store.state.userInfo.mobile;
              }
              form.yzAutoPriceItemList = form.yzAutoPriceItemList.map(
                (item) => {
                  return {
                    isNewRecord: item.isNewRecord,
                    color: item.color,
                    materialTexture: item.materialTexture.dictValue,
                    fileName: item.fileName,
                    quantity: item.quantity,
                    surfaceTreatment: item.surfaceTreatment.dictValue,
                    technology: item.technology.dictValue,
                    yzAutoPriceId: item.yzAutoPriceId,
                  };
                }
              );
              this.$emit("submit", form);
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
    del(row) {
      this.forms.yzAutoPriceItemList = this.forms.yzAutoPriceItemList.filter(
        (item) => {
          return item.yzAutoPriceId != row.yzAutoPriceId;
        }
      );
    },
    add(name = "") {
      this.forms.yzAutoPriceItemList.push({
        // sort: this.forms.yzAutoPriceItemList.length + 1,
        yzAutoPriceId: new Snowflake(
          this.forms.yzAutoPriceItemList.length + 1,
          1,
          0
        ).nextId(),
        fileName: name,
        technology: "",
        materialTexture: "",
        isNewRecord: true,
        surfaceTreatment: "",
        color: "",
        quantity: "",
        desciption: "",
        gyList: this.gyList,
        czList: [],
        bmList: [],
      });
    },
  },
};
</script>
