<template>
  <div class="home make">
    <div :class="['make-top', isShow ? 'on' : '']">
      <div class="swiper-container" id="makeBanner" v-if="!isShow">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
            <el-image :src="item.addFiles" :alt="item.adName" fit="cover"></el-image>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
        <div class="btns">
          <div @click="bannerClick">
            成为供应商
         
          </div>
          <div @click="up">发布需求</div>
        </div>
      </div>
      <div :class="['content', !isShow ? 'on' : '']">
        <make3d ref="make" @parsing="parsing" @up="up" @success="upSuccess" />
      </div>
    </div>
    <offer v-show="isShowOffer" ref="offers" @submit="offerSubmit" />
    <div class="make-content">
      <div class="content flex_b">
        <div class="servers-classify" v-if="!isWap">
         
          <a class="classify-list" @click="up" style="cursor:pointer;" target="_blank">
        <img style="width:100%" :src="`${adBack10}`" alt="" />
      </a>
        </div>
        <div class="l">

          <div class="ad-banner">
            <div class="swiper-container" id="adBanner">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in adList" :key="item.id">
                  <a :href="item.adUrl" target="_blank">
                    <el-image :src="item.addFiles" :alt="item.adName" fit="cover"></el-image>
                  </a>
                </div>
              </div>
              <div class="swiper-pagination swiper-pagination2" slot="pagination"></div>
            </div>
          </div>
        </div>
        <div class="servers-classify" v-if="isWap">
          <div class="classify-list" @click="up" :style="`background:url('${adBack10}') 0% 0%/cover no-repeat;`">
            
          </div>
         
        </div>
        <div class="r">
          <a  :href="`${adBack9Href}`" target="_blank">
        <img style="width:100%" :src="`${adBack9}`" alt="" />
      </a>
       
        </div>
      </div>
      <div class="content">
        <div class="b-ad flex">
          <template v-for="(item, index) in minAdList">
            <a :href="item.adUrl" v-if="index < 4" class="item" target="_blank" :key="index">
              <el-image :src="item.addFiles" fit="cover" lazy></el-image>
            </a>
          </template>
        </div>
      </div>
    </div>


    <div class="big-data">

<div class="big-data-main flex_b">
  <div class="map">
    <div class="title">惠享工业企业服务生态</div>
    <div id="chinaMap"></div>
    <div class="l-border"></div>
    <div class="r-border"></div>
  </div>
  <div class="data-list">
    <div class="data-t flex_b">
      <div class="statistics">
        <div class="sub-title">
          <div class="sub-title-main">
            <img src="@/assets/images/home/icon_data.png" alt="" />
            实时平台数据
            <p><span></span><span></span><span></span></p>
          </div>
        </div>
        <div class="list">
          <div class="item" v-for="item in statisticsList" :key="item.id">
            <div class="icon flex_c flex_align_c">
              <img src="@/assets/images/home/icon_data.png" alt="" />
            </div>
            <div class="name">{{ item.title }}</div>
            <div class="num">
              <span>{{ statisticsData[item.code] }}个</span>
            </div>
          </div>
        </div>
      </div>
      <div class="category">
        <div class="category-map">
          <div class="sub-title">
            <div class="sub-title-main">
              <img src="@/assets/images/home/icon_data.png" alt="" />
              实时行业数据
              <p><span></span><span></span><span></span></p>
            </div>
          </div>
          <div id="pieMap"></div>
        </div>
        <div class="btns">
          <div class="btn-item">
            <a href="https://www.huixianggongye.com//account/registerUser?yzType=1"> <span>我是供应商</span> </a>
          </div>
          <div class="btn-item">
            <span @click="up">我是需求方</span>
          </div>
        </div>
      </div>
    </div>
    <div class="data-b">
      <div class="sub-title">
        <div class="sub-title-main">
          <img src="@/assets/images/home/icon_data.png" alt="" />
          实时需求信息
          <p><span></span><span></span><span></span></p>
        </div>
      </div>
      <div class="list">
        <div class="swiper-container"  id="shishixuqiu">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in taskList"
              :key="item.id"
            >
              <div class="item flex_b">
                <div class="sort">{{ index + 1 }}</div>
                <div class="date" v-html="item.updateDate"></div>
                <div class="title"  v-html="truncatedText(item.title)"></div>
                <div class="remarks"  v-html="truncatedText2(item.description)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
    <div class="partner">
      <div class="partner-main">
        <div class="content flex_b anime">
          <div class="info-l">
            <img :src="`${adBack11}`" alt="" />
          </div>
        </div>
      </div>
    </div>
    <up-dialog :visible.sync="upVisible" />
  </div>
</template>
<script>

import chinaMap from "./mixins/chinaMap";
import pieMap from "./mixins/pieMap";
import Swiper from "@/assets/swiper/js/swiper.min.js";
import upDialog from "@/components/servers/up-dialog";
import "@/assets/swiper/css/swiper.min.css";
import make3d from "@/components/make3d/";
import offer from "@/components/offer/index";

  export default {
    mixins: [chinaMap, upDialog, pieMap],

    components: {
      make3d,
      upDialog,
      offer
    },
    data() {
    return {
      upVisible: false,
        serversTabList: [],
        flowList: [{
            id: 1,
            title: "需求发布与审核",
            label: ["发布需求", "生产评估，降本增效", "一键发布，极速报价"],
          },
          {
            id: 2,
            title: "选厂报价",
            label: ["多方极速报价", "1v1专业顾问", "严选工厂，在线看厂"],
          },
          {
            id: 3,
            title: "协同生产",
            label: ["在线商务", "可视化生产进程", "交易保证"],
          },
          {
            id: 4,
            title: "极速发货",
            label: ["在线检验", "品质保障", "验收付款"],
          },
        ],
        menuActive: "1",
        menuList: [{
            id: 1,
            title: "批量订购",
            url: ""
          },
          {
            id: 2,
            title: "品牌一览",
            url: ""
          },
          {
            id: 3,
            title: "账务平台",
            url: ""
          },
          {
            id: 4,
            title: "型号转换管理",
            url: ""
          },
          {
            id: 5,
            title: "使用指南",
            url: ""
          },
          {
            id: 6,
            title: "订购",
            url: ""
          },
          {
            id: 7,
            title: "品牌一览",
            url: ""
          },
        ],
        menuList2: [{
            id: 1,
            title: "目录与软件",
            url: ""
          },
          {
            id: 2,
            title: "申请设计支援",
            url: ""
          },
          {
            id: 3,
            title: "软件技术之窗",
            url: ""
          },
          {
            id: 4,
            title: "案例库CAD",
            url: ""
          },
          {
            id: 5,
            title: "下载记录",
            url: ""
          },
          {
            id: 6,
            title: "技术咨询",
            url: ""
          },
        ],
        orderList: [{
            id: 1,
            icon: "icon-daifukuan",
            title: "待付款",
            url: ""
          },
          {
            id: 2,
            icon: "icon-daifahuo1",
            title: "生产进程",
            url: ""
          },
          {
            id: 3,
            icon: "icon-yifahuo",
            title: "已发货",
            url: ""
          },
          {
            id: 4,
            icon: "icon-dingdan",
            title: "全部订单",
            url: ""
          },
        ],
        orderList2: [{
            id: 1,
            icon: "icon-daifahuo1",
            title: "待确认",
            url: ""
          },
          {
            id: 2,
            icon: "icon-dingdan",
            title: "全部报价",
            url: ""
          },
        ],
        orderActive: "1",
        bannerList: [],
        bannerList2: [],
        isShow: false,
        adList: [],
        minAdList: [],
        menu2List: [
          // { id: 4, title: "设计师支援", url: "" },
          // { id: 5, title: "供应商合作", url: "" },
        ],
        classifyList: [{id:1,name:"供需大厅",href:"/home"},{id:2,name:"企业大厅",href:"/company/list"},{id:3,name:"平台资讯",href:"/supplier/list"},{id:4,name:"惠享学院",href:"/support"},{id:5,name:"展会赛事",href:"/match/list"},{id:6,name:"关于我们",href:"/me"}
],
        yzShopMainList: [],
        yzShopInfoRecommendList: [],
        serversTabActive: "",
        form: {},
        isShowOffer: false,
        offerId: "",
        adBack5:"",
        adBack6:"",
        adBack7:"",
        adBack8:"",
        adBack9:"",      adBack9Href:"",   
		adBack10:"",
        adBack11:"",
      industrialList: [
        {
          id: 1,
          icon: require("@/assets/images/home/icon1.png"),
          title: "YZCloud-惠享工业企业服务平台云计算中心",
          desc: "为中小企业提供免费的私有云、公有云、混合云解决方案",
          label: ["数据中心", "虚拟云服务器", "网络存储"],
        },
        {
          id: 2,
          icon: require("@/assets/images/home/icon2.png"),
          title: "工业大数据平台H-BDP",
          desc: "基于云计算的智能化工业大数据平台",
          label: ["机械加工业", "汽车行业", "电子信息行业"],
        },
        {
          id: 3,
          icon: require("@/assets/images/home/icon3.png"),
          title: "工业知识图谱H-IKG",
          desc: "工业知识的管理和应用服务平台",
          label: ["装备制造业", "汽车行业", "电子信息行业"],
        },
        {
          id: 4,
          icon: require("@/assets/images/home/icon4.png"),
          title: "工业设备智能维保平台H-IEM",
          desc: "整合维保资源，提供标准化维保服务",
          label: ["机械加工业", "汽车行业", "装备制造业"],
        },
      ],
      activeName: "1",
      solutionList: [
        {
          id: 1,
          back: require("@/assets/images/home/merit1.jpg"),
          title: "能连接",
          desc: "低成本、普适应的链接，即插即用，99%+自动识别",
        },
        {
          id: 2,
          back: require("@/assets/images/home/merit2.jpg"),
          title: "强平台",
          desc: "做强工业属性的 灵活可配置中台，100%通过微服务支持上层应用需求",
        },
        {
          id: 3,
          back: require("@/assets/images/home/merit3.jpg"),
          title: "够安全",
          desc: "全方位保障客户安全，端到端全链路安全",
        },
        {
          id: 4,
          back: require("@/assets/images/home/merit4.jpg"),
          title: "多应用",
          desc:
            "赋能行业龙头，打造突现工业价值的应用，客户的SaaS投入产出效益10倍+",
        },
        {
          id: 5,
          back: require("@/assets/images/home/merit5.jpg"),
          title: "高智能",
          desc:
            "以AI为第一生产力，基于OT+IT数据，加工分析，建模学习，挖掘工业核心价值",
        },
      ],
      statisticsData: {
        platformActiveUsersNumber: "0", //平台活跃用户数
        platformEnterpriseServiceProvider: "0", //企业级服务商
        platformEnterprisesCloud: "0", //助力企业上云
        platformEnterprisesCloudDevice: "0", //上云设备
        platformOrderGMV: "0", //订单GMV
        platformRegisteredUsersNumber: "0", //平台注册用户数
      },
      statisticsList: [
        { id: 1, title: "平台活跃用户数", code: "platformActiveUsersNumber" },
        { id: 2, title: "订单GMV", code: "platformOrderGMV" },
        {
          id: 3,
          title: "平台注册用户数",
          code: "platformRegisteredUsersNumber",
        },
        { id: 4, title: "助力企业上云", code: "platformEnterprisesCloud" },
        {
          id: 5,
          title: "企业级服务商",
          code: "platformEnterpriseServiceProvider",
        },
        { id: 6, title: "上云设备", code: "platformEnterprisesCloudDevice" },
      ],
      bannerTextList: [
 
      ],
      taskList: [],
    };
  },

    methods: {

   
    truncatedText(text) {
      if (text.length > 12) {
        return text.substring(0, 12) + '...';
      } else {
        return text;
      }
    },
 
    truncatedText2(text) {
      if (text.length > 40) {
        return text.substring(0, 40) + '...';
      } else {
        return text;
      }
    },



      offerSubmit(form) {
        console.log(form);
        this.form = form;
        this.$api
          .post("/api/v1/saveAutoPrice", this.$common.getFormData(form))
          .then((res) => {
            if (res.code == 1) {
              this.offerId = res.data.id;
              this.$refs.make.submit();
            }
          });
      },
      parsing(list) {
        this.isShowOffer = true;
        this.$refs.offers.appendList(list);
      },
      goSave() {
        this.$refs.offers.over();
        this.$refs.make.reset();
        this.$message({
          message: "提交报价成功！",
          type: "success",
          duration: 1500,
        });
      },
      goAbout(files, i = 0) {
        this.$api
          .post(
            "/api/v1/uploadAutoPriceFile",
            this.$common.getFormData({
              fileUploadId: files[i].fileUploadId,
              id: this.offerId,
            })
          )
          .then((res) => {
            debugger;
            if (res.code == 1) {
              if (files.length - 1 == i) {
                this.goSave();
              } else {
                i++;
                this.goAbout(files, i);
              }
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 1500,
              });
              this.btnLoading = false;
            }
          })
          .catch((err) => {
            this.$message({
              message: err,
              type: "error",
              duration: 1500,
            });
            this.btnLoading = false;
          });
      },
      upSuccess(files) {
        if (files) {
          this.goAbout(files);
        }
      },
      goSupplier() {
        location.href = "/supplier/list";
      },
      goChat() {
        var tempwindow = window.open("_blank");
        tempwindow.location.href = "https://www.huixianggongye.com/f/contactKefu";
      },
      up() {
        this.upVisible = true;
      },
      toSupplier(item, items) {
        location.href = `${item.href}`;
      },
      serversTabChange(item) {
        this.serversTabActive = item.dictValue;
        this.getYzShopMainList();
      },
      bannerClick() {
        location.href = "https://www.huixianggongye.com/account/registerUser";
      },
      async getBannerData() {
        await this.$sapi.get("/api/v1/getAdList-3.json").then((res) => {
          this.bannerList = res.list.map((item) => {
            // item.addFiles = item.addFiles.replace('http://yizao.oss-cn-qingdao.aliyuncs.com/','https://static.huixianggongye.com/')
            return item;
          });
          this.$nextTick(() => {
            new Swiper("#makeBanner", {direction: "horizontal", // 垂直切换选项
              autoplay: {
                delay: 3500,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              },
              observer: true,
              observeParents: true,
              pagination: {
                el: ".swiper-pagination1",
                clickable: true,
              },
            });
          });
        });
        //首页banner
        await this.$sapi.get("/api/v1/getAdList-1.json").then((res) => {
    
          this.adList = res.list;
          this.$nextTick(() => {
            new Swiper("#adBanner", {
              direction: "horizontal", // 垂直切换选项
              autoplay: {
                delay: 3500,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              },
              observer: true,
              observeParents: true,
              pagination: {
                el: ".swiper-pagination2",
                clickable: true,
              },
            });
          });
        });
      },
      getClassifyData() {
        this.$api.get("/api/v1/getYzTaskCategoryList").then((res) => {
          let _json = {};
          let pList = [];
          let list = res.filter((item) => {
            if (item.parentCode == "0") {
              _json[item.id] = [];
              pList.push(item);
            }
            return item.parentCode != "0";
          });
          list.forEach((item) => {
            if (_json[item.parentCode]) _json[item.parentCode].push(item);
          });
       
         
        });
      },
      getYzShopMainList() {
        this.$api
          .get("/api/v1/getYzShopMainList", {
            params: {
              pageNo: 1,
              pageSize: 12,
            },
          })
          .then((res) => {
            this.yzShopMainList = (res.list || res.data.list).map((item) => {
              item.yzShopMainNeedTags = item.yzShopMainNeedTags ?
                item.yzShopMainNeedTags.split(",") : [];
              return item;
            });
          });
      },
      getDict() {
        this.$sapi
          .get("/api/v1/getDictDataList-yz_index_recommend_service.json")
          .then((res) => {
            this.serversTabList = res;
          });
      },
      getYzShopInfoRecommendList() {
        this.$api
          .get("/api/v1/getYzShopInfoRecommendList", {
            params: {
              pageNo: 1,
              pageSize: 12,
            },
          })
          .then((res) => {
            this.yzShopInfoRecommendList = res.list || res.data.list;
          });
      },
    getAllStatistics() {
      this.$api.get("/api/v1/getAllStatistics.json").then((res) => {
        this.statisticsData = res;
      });
    },
    setSwiper() {
      this.$sapi.get("/api/v1/getAllTaskInfo.json").then((res) => {
        this.taskList = res.list;
        this.$nextTick(() => {
          new Swiper("#shishixuqiu", {
            direction: "vertical", // 垂直切换选项
            slidesPerView: 6,
            autoplay: {
              delay: 5000,
              stopOnLastSlide: false,
              disableOnInteraction: true,
            },
          });
        });
      });
    },
    showAnimate(obj) {
      let _span = $(obj).find("p span");
      let timeObj = setTimeout(() => {
        _span.eq(0).show();
        clearTimeout(timeObj);
        timeObj = setTimeout((_) => {
          clearTimeout(timeObj);
          _span.eq(1).show();
          timeObj = setTimeout((_) => {
            clearTimeout(timeObj);
            _span.eq(2).show();
            timeObj = setTimeout((_) => {
              clearTimeout(timeObj);
              _span.hide();
              this.showAnimate(obj);
            }, 500);
          }, 500);
        }, 500);
      }, 500);
    },
  },
  created() {
    console.log("home-");
    this.getAllStatistics();
    this.setSwiper();
    /*监听窗口变化，重置echarts表格*/
    window.onresize = () => {
      this.mycharts1.resize();
      this.mycharts2.resize();
    };
    setTimeout(() => {
      this.$store.commit("anime");
    }, 300);
  },
    computed: {
      userInfo() {
        return this.$store.state.userInfo;
      },
      isWap() {
        return this.$store.state.isWap;
      }
    },
  mounted() {

    let _this = this;
    $(".sub-title .sub-title-main").each(function () {
      _this.showAnimate(this);
    });
    if (this.$route.query.up) {
        this.up();
      }
  


      this.$parent.finish();
    //3d预览banner
    this.getBannerData();
     //获取服务分类
     this.getClassifyData();
      //获取推荐服务
      this.getYzShopMainList();
      //字典
      this.getDict();
      //推荐服务商
      this.getYzShopInfoRecommendList();
      //小类目banner
      this.$sapi.get("/api/v1/getAdList-2.json").then((res) => {
        this.minAdList =
          res.list.length >= 4 ? res.list : [...res.list, ...res.list];
      });

      this.$sapi.get('/api/v1/getAdList-6.json').then(res=>{
        this.adBack6 = res.list[0].mobileImage;
      })
      this.$sapi.get('/api/v1/getAdList-7.json').then(res=>{
        this.adBack7 = res.list[0].mobileImage;
      })
      this.$sapi.get('/api/v1/getAdList-8.json').then(res=>{
        this.adBack8 = res.list[0].mobileImage;
      })
      this.$sapi.get('/api/v1/getAdList-9.json').then(res=>{

        this.adBack9 = res.list[0].mobileImage;

        this.adBack9Href= res.list[0].adUrl;
      })
      this.$sapi.get('/api/v1/getAdList-10.json').then(res=>{
        this.adBack10 = res.list[0].mobileImage;
      })
      this.$sapi.get('/api/v1/getAdList-11.json').then(res=>{
        this.adBack11 = res.list[0].mobileImage;
      })
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/home.scss";
@import '@/assets/scss/index_plus.scss';

</style>